/* Chip (bootstrap italia component customization) */

.chip {
  align-items: initial;
  margin: 5px 12px 5px 0;
  padding: 0 16px 2px 13px;

  &,
  &:hover:not(.chip-disabled) {
    background: #06c;
    color: #fff;
    border: none;
  }

  .chip-label {
    min-width: 42px;
    color: #fff;
    font-size: rem(12px);
    line-height: rem(24px);
    font-weight: normal;
    height: auto;
    transform: none;
  }

  button {
    display: block;
    width: 24px;
    height: 24px;
    padding: 8px;
    margin-right: -12px;
    margin-left: 10px;

    .svg-icon {
      display: block;
      width: 8px;
      height: 8px;
      color: #fff;
    }
  }
}

/* Chip Bar */

.chip-bar {
  padding: 15px 0;
  background-color: #fff;
}

.chip-bar__list {
  display: flex;
}

/* Chip separator */

.chip-separator {
  display: inline-block;
  margin: 5px 22px 5px 10px;
  height: 26px;
  width: 1px;
  background: #979797;
}
