@import '../../stylesheets/common';

.sondaggio {
  margin: 0 -32px;
  padding: 20px 32px 25px;
  background: #fdeedd;

  @include media-breakpoint-up(sm) {
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px 46px 35px;
  }
}

.sondaggio__title {
  margin-bottom: 30px;
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.3px);
  font-size: rem(20px);
  line-height: rem(24px);

  @include media-breakpoint-up(lg) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@include media-breakpoint-down(md) {
  .sondaggio__body .form-check {
    margin-top: 20px;
  }
}

.sondaggio__foot {
  padding-top: 40px;

  .btn-hidden {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 20px;
  }
}
