@import "../../stylesheets/common";

.tcl-banner-site {
  position: relative;

  .banner-site__icon {
    svg {
      width: 110px;
      height: 144px;
      margin-right: 40px;
    }
  }

  .tcl-banner-site__inner {
    position: relative;
    padding: 33px 0;
  }

  .tcl-banner-site__media {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .tcl-picture {
    img {
      border-radius: 11px;
    }
  }

  .tcl-banner-site__content {
    max-width: 628px;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .banner-site__info {
    display: flex;
    flex-direction: column;

    .banner-site__title {
      * {
        @include heading-h3();
      }
    }
  }

  .banner-site__abstract {
    p {
      margin: 0;
    }
  }
}

/* BREAKPOINT < MD */
@include media-breakpoint-down(md) {
  .tcl-banner-site .tcl-picture img {
    object-position: right;
  }

  .tcl-banner-site .tcl-banner-site__content {
    padding: 0 20px;
  }
}

/* BREAKPOINT < XS */
@include media-breakpoint-down(xs) {
  .tcl-banner-site .banner-site__icon {
    margin-bottom: auto;

    svg {
      width: 50px;
      height: 65px;
      margin-right: 25px;
    }
  }
}
