
// fade image in after is loaded by the lazysizes js library

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: $transition-fade;
}

/* Block: tcl-picture */

.tcl-picture {
  display: inline-block;
  background-color: color('gray-400');
}

.tcl-picture__image {
  display: block;
  max-width: 100%;
}

/* Modifier: tcl-picture--cover */

.tcl-picture--cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // // intrinsic aspect ratio (not implemented)
  // &::before {
  //   display: block;
  //   width: 100%;
  //   content: '';
  //   padding-bottom: 100%;
  //   height: 0;
  // }

  .tcl-picture__image {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover"; // stylelint-disable-line font-family-no-missing-generic-family-keyword
  }
}

// force size of the image cloned by ls.object-fit polyfill
.lazysizes-display-clone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
