
// =========================================================================
//
//  PRECISE CONTROL OVER RESPONSIVE TYPOGRAPHY FOR SASS
//  ---------------------------------------------------
//  Indrek Paas @indrekpaas
//
//  Inspired by Mike Riethmuller's Precise control over responsive typography
//  http://madebymike.com.au/writing/precise-control-responsive-typography/
//
//  Borrowed `strip-unit` function from Hugo Giraudel
//  https://css-tricks.com/snippets/sass/strip-unit-function/
//
//  02.04.2018 Remove `screen` keyword from media queries
//  11.08.2016 Remove redundant `&` self-reference
//  31.03.2016 Remove redundant parenthesis from output
//  02.10.2015 Add support for multiple properties
//  24.04.2015 Initial release
//
// =========================================================================

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

// Website specific text styles
//
// Headings

@mixin heading-h0 {
  font-family: $font-family-serif;
  font-weight: 700;
  font-size: rem(32px);
  line-height: rem(40px);

  @include media-breakpoint-up(lg) {
    font-weight: 900;
    font-size: rem(50px);
    line-height: rem(53px);
  }
}

@mixin heading-h1 {
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.5px);
  font-size: rem(20px);
  line-height: rem(24px);

  @include media-breakpoint-up(lg) {
    font-weight: 900;
    font-size: $h1-font-size;
    line-height: rem(48px);
  }
}

@mixin heading-h2 {
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.5px);
  font-size: rem(20px);
  line-height: rem(24px);

  @include media-breakpoint-up(lg) {
    font-size: $h2-font-size;
    line-height: rem(40px);
  }
}

@mixin heading-h3 {
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.47px);
  font-size: rem(20px);
  line-height: rem(24px);

  @include media-breakpoint-up(lg) {
    font-size: $h3-font-size;
    line-height: rem(32px);
  }
}

@mixin heading-h4 {
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.3px);
  font-size: rem(20px);
  line-height: rem(24px);

  @include media-breakpoint-up(lg) {
    font-size: $h4-font-size;
    line-height: rem(28px);
  }
}

@mixin heading-h5 {
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.3px);
  font-size: $h5-font-size;
  line-height: rem(24px);
}

@mixin heading-h6 {
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.3px);
  font-size: $h6-font-size;
  line-height: rem(19px);
}

// Occhiello

@mixin occhiello-1 {
  font-family: $font-family-serif;
  font-weight: 300;
  letter-spacing: rem(0.1px);
  font-size: rem(16px);
  line-height: rem(24px);
  text-transform: uppercase;
}

// Links

@mixin link-14 {
  color: #e81b44;
  font-weight: 500;
  font-size: rem(14px);
  line-height: rem(20px);
}

@mixin link-16 {
  color: #e81b44;
  font-weight: 500;
  font-size: rem(16px);
  line-height: rem(24px);
}

@mixin link-18 {
  color: #e81b44;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(28px);
}

// Text

@mixin text-14 {
  color: #333;
  font-weight: 300;
  font-size: rem(13px);
  line-height: rem(20px);
}

@mixin text-15 {
  color: #333;
  font-weight: 300;
  font-size: rem(15px);
  line-height: rem(24px);
}

@mixin text-18 {
  color: #333;
  font-weight: 300;
  font-size: rem(18px);
  line-height: rem(28px);
}

// "Tags"

@mixin text-tag-medium {
  color: #b4b4b4;
  font-size: rem(11px);
  line-height: rem(16px);
}

// Blockquotes

blockquote,
.blockquote {
  margin: 50px 0;
  font-family: $font-family-serif;
  font-size: rem(25px);
  line-height: rem(32px);
  letter-spacing: rem(-0.47px);
  padding-left: 30px;
  border-left: solid 6px #009bcf;
}
