@import '../../stylesheets/common';

.list-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  border-bottom: solid 1px #979797;
}

.list-head__main {
  display: flex;
  flex-direction: column;
}

.list-head__title {
  @include heading-h5();

  margin: 6px 0 10px;
  color: color('orange');
}

.list-head__subtitle {
  margin-bottom: 10px;
  font-size: rem(13px);
  line-height: rem(20px);
  letter-spacing: rem(-0.28px);
  color: color('lead');
}

.list-head__main + .list-head__ctas {
  margin-left: 20px;
}

/* Modifier: title-lg */

.list-head--title-lg .list-head__title {
  font-size: rem(28px);
  line-height: rem(32px);
  letter-spacing: rem(-0.47px);
}

/* Modifier: title-xl */

.list-head--title-xl {
  .list-head__title {
    font-family: $font-family-serif;
    font-size: rem(40px);
    line-height: rem(46px);
    letter-spacing: rem(-0.5px);
    font-weight: 900;
    color: $gray-800;
  }

  @include media-breakpoint-down(xs) {
    .list-head__main + .list-head__ctas a {
      padding: 12px 0;
    }
  }
}

/* Modifier: no-line */

.list-head--no-line {
  .list-head__title {
    color: $primary;
  }

  @include media-breakpoint-up(lg) {
    border-bottom: 0;

    .list-head__title {
      color: $gray-800;
      font-size: rem(24px);
      line-height: rem(28px);
    }
  }
}
