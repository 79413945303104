@each $name in map-keys($colors) {
  $hex: map-get($colors, $name);

  .color-text--#{$name} {
    color: $hex;
  }

  .color-bg--#{$name} {
    background-color: $hex;
  }
}
