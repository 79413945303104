
.dropdown {
  .link-list-wrapper ul li a {
    font-size: rem(16px);

    span,
    &:hover:not(.disabled) span {
      color: color('lead');
    }
  }
}

.btn-dropdown {
  font-size: rem(16px);
  font-weight: normal;
  color: color('lead');
  text-transform: none;

  .icon-expand {
    margin-left: 4px;
    color: #000;
    transform: rotate(90deg);
  }

  &[aria-expanded=true] .icon-expand {
    transform: scaleY(-1) rotate(90deg);
  }
}
