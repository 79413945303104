
/* Block: tcl-video */

.tcl-video {
  display: inline-block;
  background-color: color('gray-400');
}

.tcl-video__content {
  display: block;
  width: 100%;
}

/* Modifier: tcl-video--cover */

.tcl-video--cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // // intrinsic aspect ratio (not implemented)
  // &::before {
  //   display: block;
  //   width: 100%;
  //   content: '';
  //   padding-bottom: 100%;
  //   height: 0;
  // }

  .tcl-video__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover"; // stylelint-disable-line font-family-no-missing-generic-family-keyword
  }
}
