@import '../../stylesheets/common';

.tcl-external-link {
  background-color: $white;
  padding: 27px 15px;
}

.tcl-external-link__row:last-child {
  &::after {
    content: '';
    width: calc(100% - 30px);
    height: 1px;
    margin-left: 15px;
    background: #979797;
  }
}

.tcl-external-link__col {
  cursor: pointer;
}

.tcl-external-link__cta {
  @include text-14();

  display: flex;
  align-items: center;
  text-decoration: unset;
  margin-bottom: 7px;

  &:hover {
    text-decoration: unset;
  }

  svg {
    width: 12px;
    height: 12px;
    margin-right: 7px;
  }
}

.tcl-external-link__abstract {
  h4 {
    @include heading-h6();

    color: #2d2d2d;
  }
}

/* BREAKPOINT < SM */
@include media-breakpoint-down(sm) {
  .tcl-external-link {
    border-bottom: 28px solid #f7f7f7;
  }

  .tcl-external-link__row:last-child {
    > div {
      margin-bottom: 10px;
    }
  }
}
