// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-500: #959da6 !default;
$gray-800: #202020 !default;
$black: #000 !default;
$orange: #eb7d01 !default;
$blue: #002862 !default;
$azure: #009bcf !default;
$azure-dark: #009bcf !default; // (Valore che rispetta il contrasto ADA: #0075db)
$lead: #455b71 !default;

$primary: $orange !default;
$secondary: $blue !default;
$info: #909090 !default;
$dark: $lead !default;

$colors: (
  "white": $white,
  "gray-500": $gray-500,
  "gray-800": $gray-800,
  "black": $black,
  "orange": $orange,
  "blue": $blue,
  "azure": $azure,
  "azure-dark": $azure-dark,
  "lead": $lead,
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
// Standard Options:
$enable-rounded: true !default;
// Custom Options:
$enable-font-face: true !default;
$enable-font-alternative: false !default;

// Spacing
//

$spacer: 25px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 0.25),
    2: ($spacer * 0.5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 3),
    7: ($spacer * 4),
    8: ($spacer * 6),
    9: ($spacer * 8),
  ),
  $spacers
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-800 !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1214px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$grid-row-columns:            6 !default;

// Components
//
// Define common padding and border radius sizes and more.
$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;
$transition-collapse-max-height: max-height 0.35s ease !default;
$transition-color: color 0.15s ease-in-out !default;
$transition-border-color: border-color 0.15s ease-in-out !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// IMPORTANT: do not use $font-family-* variables to set font-family; use mixins defined in mixins/_fonts.scss insead.
$font-family-sans-serif:  'Work Sans', sans-serif !default;
$font-family-serif: 'museo-slab', serif !default;

$font-family-fallback: 'arial', 'helvetica', sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

// To support chinese, japanese or korean:
// - set $enable-font-alternative to true
// - Set the following variables to the corresponding font to address one of:
//   Simplified Chinese, Traditional Chinese, Japanese, Korean
//
// See also:
//  https://fonts.google.com/earlyaccess#Noto+Sans+TC
//  https://www.google.com/get/noto/help/cjk/
//
$font-family-alternative: 'Noto Sans', 'Noto Sans TC', sans-serif;
$font-face-alternative-url: '//fonts.googleapis.com/earlyaccess/notosanstc.css';

$font-weight-normal: 300 !default;

$font-size-base: 1rem !default; // Base: 16px (assumes the browser default it 16px)
$font-size-lg: $font-size-base * 2 !default; //
$font-size-sm: $font-size-base * 0.875 !default;

$line-height-base: multiplier(24px) !default;

$h1-font-size: rem(40px);
$h2-font-size: rem(32px);
$h3-font-size: rem(28px);
$h4-font-size: rem(24px);
$h5-font-size: rem(20px);
$h6-font-size: rem(16px);

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         rem(9px) !default;
$input-btn-padding-x:         rem(15px) !default;
$input-btn-font-size:         rem(14px) !default;
$input-btn-line-height:       multiplier(24px, 14px) !default;
$input-btn-border-width:      0 !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-border-radius:           4px !default;
$btn-border-radius-lg:        4px !default;
$btn-border-radius-sm:        4px !default;

// Badges

$badge-font-size:                   rem(9px) !default;
$badge-font-weight:                 500 !default;
$badge-padding-y:                   6px !default;
$badge-padding-x:                   8px !default;
$badge-border-radius:               5px !default;

// Header height
$preheader-height-desktop: 48px;
$header-height-desktop: 74px;
$header-height-mobile: 65px;

// Link and button colors
//$link-color: $azure !default; // see _variables-overwrite.scss
$link-hover-color: $azure !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// Custom
$zindex-header: 800 !default;
$zindex-menu-backdrop: 810 !default;
$zindex-menu: 820 !default;

// Bootstrap
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;
