@import '../../stylesheets/common';

.tcl-section-head {
  position: relative;
}

.tcl-section-head__icon {
  color: $black;
  margin-bottom: 32px;

  .svg-icon {
    width: 100px;
    height: 100px;
  }
}

.tcl-section-head__occhiello {
  @include occhiello-1();

  margin-bottom: 12px;
  color: $black;
}

.tcl-section-head__title {
  margin-bottom: 24px;
  color: $black;
}

.tcl-section-head__title--h1 {
  @include heading-h1();
}

.tcl-section-head__title--h2 {
  @include heading-h2();
}

.tcl-section-head__title--h3 {
  @include heading-h3();
}

.tcl-section-head__title--h4 {
  @include heading-h4();
}

.tcl-section-head__title--h5 {
  @include heading-h5();
}

.tcl-section-head__title--h6 {
  @include heading-h6();
}

.tcl-section-head__abstract {
  margin-bottom: 24px;
  color: #333;
}
