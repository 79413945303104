@import '~slick-carousel/slick/slick';

/* Variables */
$slick-zindex-controls: 10;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 0.6;
$slick-opacity-not-active: 0;
$slick-dot-size: 6px;
$slick-dot-color: #ccc;
$slick-dot-color-active: #000;

/* Custom slick theme */

// /* Slider */
// .slick-list {
//     .slick-loading & {
//         background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
//     }
// }

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 45px;
  width: 45px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  opacity: $slick-opacity-default;
  transition: opacity ease-in 0.4s;
  z-index: $slick-zindex-controls;

  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    opacity: $slick-opacity-on-hover;
  }

  &.slick-disabled {
    opacity: $slick-opacity-not-active;
  }

  /* BREAKPOINT <= XS */
  @include media-breakpoint-down(xs) {
    display: none !important;
  }
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
  transform: translate(0, -50%) rotate(180deg);
}

/* Dots */

.slick-dots {
  position: absolute;
  bottom: 5px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: $slick-zindex-controls;

  li {
    position: relative;
    display: inline-block;
    margin: 0 1px;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 45px;
      width: 14px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      cursor: pointer;

      &:hover,
      &:focus {
        outline: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -($slick-dot-size / 2);
        margin-left: -($slick-dot-size / 2);
        width: $slick-dot-size;
        height: $slick-dot-size;
        line-height: $slick-dot-size;
        background: $slick-dot-color;
        border-radius: $slick-dot-size;
      }
    }

    &.slick-active button::before {
      background: $slick-dot-color-active;
    }
  }
}
