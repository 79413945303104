@import '../../stylesheets/common';

.tcl-badge-list {
  display: flex;
  justify-content: flex-start;

  .badge {
    margin-bottom: 11px;
  }

  @include media-breakpoint-up(lg) {
    justify-content: center;
  }
}
