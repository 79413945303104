@import '../../stylesheets/common';

.tcl-pre-footer {
  position: relative;
  padding: 42px 0;
  background-color: #f7f7f7;
}

.tcl-pre-footer__content {
  > div {
    &:last-child {
      .tcl-pre-footer__box {
        background-color: $azure;
        color: $white;

        .tcl-pre-footer__abstract {
          color: $white;
        }
      }
    }
  }
}

.tcl-pre-footer__box {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  color: $dark;
  height: 100%;
  padding: 21px 30px 29px;
  border-radius: 6px;
  box-shadow: 0 2px 19px 0 rgba(202, 202, 202, 0.5);
}

.tcl-pre-footer__info {
  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.tcl-pre-footer__title {
  @include heading-h5();

  display: block;
  margin-bottom: 11px;
}

.tcl-pre-footer__abstract {
  @include text-14();

  margin: 0 0 11px;
  font-family: $font-family-serif;
  letter-spacing: rem(-0.28px);
  color: $dark;
}

.tcl-pre-footer__icon {
  padding-left: 45px;

  svg {
    width: 50px;
    height: 53.04px;
  }
}

.tcl-pre-footer__form {
  .form-row {
    margin: 0;
  }

  .form-group {
    width: 100%;
    margin-bottom: 0;

    .form-control {
      background-color: $dark;
      color: $white;
      opacity: 0.36;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 46px;
      border: 0;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $white;
        opacity: 0.63; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $white;
        opacity: 0.63;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $white;
        opacity: 0.63;
      }
    }

    .invalid-feedback {
      color: $white;
      position: absolute;
      margin-top: 0;
    }
  }

  .btn {
    position: absolute;
    right: 45px;
    margin: 0;
    padding: 0.688rem 0.9375rem;
    min-width: unset;
    z-index: 99;
  }
}

/* BREAKPOINT < MD */
@include media-breakpoint-down(md) {
  .tcl-pre-footer__content {
    > div {
      margin-bottom: 27px;
    }
  }
}
