@import '../../stylesheets/common';

.tcl-gray-box {
  margin: 50px auto;

  @include media-breakpoint-up(lg) {
    margin-bottom: 130px;
  }
}

.tcl-gray-box__title {
  display: block;
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.3px);
  font-size: rem(24px);
  line-height: rem(28px);
  color: $black;
  margin-bottom: 22px;
}

.tcl-gray-box__column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tcl-gray-box__cta {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;

  a {
    font-family: $font-family-serif;
    font-weight: 700;
    letter-spacing: rem(-0.3px);
    font-size: rem(20px);
    line-height: rem(24px);
    color: $gray-800;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 60px 15px;
    text-decoration: unset;

    &:hover {
      text-decoration: unset;
    }
  }
}

/* BREAKPOINT < MD */
@include media-breakpoint-down(md) {
  .tcl-gray-box__column {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
