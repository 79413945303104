.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  @include make-container-cliclavoro-fix();
}

.container--splitted-layout {
  .container-sidebar {
    position: relative;
    padding: 0;

    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 24.66666%);
      height: 100%;
      left: -16.66666%;
      top: 0;
      background-color: #f7f7f7;
      z-index: -1;
      opacity: 0.5;
    }

    /* BREAKPOINT < MD */
    @include media-breakpoint-down(md) {
      &::after {
        display: none;
      }
    }
  }

  /* BREAKPOINT < MD */
  @include media-breakpoint-down(md) {
    padding: 0;

    .container-sidebar {
      padding: 0 15px;
    }
  }

  /* BREAKPOINT < SM */
  @include media-breakpoint-down(sm) {
    > .row {
      margin: 0;

      > div {
        padding: 0;
      }
    }
  }
}
