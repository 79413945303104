// Utility mixins and functions for evalutating source code across our variables, maps, and mixins.

// Reverse the order of a SASS map.
@function map-reverse($map) {
  $result: null;
  @if type-of($map)=="map" {
    $keys: map-keys($map);
    $map-reversed: ();
    @for $i from length($keys) through 1 {
      $map-reversed: map-merge($map-reversed, (nth($keys, $i): map-get($map, nth($keys, $i))));
    }
    @if type-of($map-reversed)=="map" {
      $result: $map-reversed;
    }
    @else {
      @warn 'There was an error reversing the order of "#{$map}"';
    }
  }
  @else {
    @warn '"#{$map}" is not a valid map';
  }
  @return $result;
}

// Remove the unit from the given numeric value (eg: '10px' -> 10)
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

// Convert the give pixel size to rem equivalent, assuming 16px as base font size
// (see $font-size-base in _variables.scss).
@function rem($size, $base: 16px) {
  $remSize: $size / $base;
  @return $remSize * 1rem;
}

// Convert the give pixel size to scalar factor equivalent, assuming 16px as base font size
// (see $font-size-base in _variables.scss).
@function multiplier($size, $base: 16px) {
  $multiplier: $size / $base;
  @return $multiplier;
}

// Convert the give pixel size to a viewport width (vw unit) equivalent, given the base viewport width in pixel.
@function vw($size, $base-viewport-width: 1024) {
  $vwSize: $size / $base-viewport-width * 100;
  @return $vwSize * 1vw;
}
