@import '../../stylesheets/common';

.tcl-article-heading {
  .badge {
    margin-bottom: 24px;
  }

  .tcl-sharer {
    padding: 0;
    margin-top: 12px;
  }
}

.tcl-article-heading__title {
  display: block;

  * {
    @include heading-h1();

    color: $gray-800;
    margin-bottom: 19px;

    /* BREAKPOINT < MD */
    @include media-breakpoint-down(md) {
      @include heading-h0();

      margin-bottom: 5px;
    }
  }

  &.tcl-article-heading__title--big-title {
    h1 {
      @include heading-h0();
    }
  }
}

.tcl-article-heading__subtitle {
  h2 {
    color: $gray-800;
    margin-top: 10px;
    margin-bottom: 41px;
    font-weight: 300;
  }
}

.tcl-article-heading__date {
  display: inline-flex;

  span {
    font-family: $font-family-serif;
    font-weight: 300;
    font-size: rem(13px);
    line-height: rem(20px);
    letter-spacing: rem(-0.28px);
    color: $dark;
    margin-right: 18px;
  }
}

.tcl-article-heading__places {
  display: inline-flex;

  span {
    font-family: $font-family-serif;
    font-weight: 300;
    font-size: rem(13px);
    line-height: rem(20px);
    letter-spacing: rem(-0.28px);
    color: $azure;
  }
}

.tcl-article-heading__label {
  position: absolute;
  display: inline-flex;
  right: 15px;

  span {
    font-family: $font-family-serif;
    font-weight: 300;
    font-size: rem(13px);
    line-height: normal;
    letter-spacing: rem(-0.28px);
    color: $white;
    background-color: $orange;
    text-transform: uppercase;
    padding: 6px 15px;
    border-radius: 6px;
  }
}

.tcl-article-heading__read-timing {
  display: inline-flex;

  span {
    position: relative;
    font-family: $font-family-serif;
    font-weight: 900;
    font-size: rem(13px);
    line-height: rem(20px);
    letter-spacing: rem(-0.28px);
    color: $dark;

    &.tcl-article-heading__read-timing__val {
      margin-right: 3px;

      &::after {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        left: -12px;
        top: 50%;
        margin-top: -2.6px;
        background-color: $dark;
      }
    }
  }
}

.tcl-article-heading__image {
  margin-top: 47px;

  /* BREAKPOINT < MD */
  @include media-breakpoint-down(md) {
    margin-top: 6px;
  }

  .tcl-picture,
  .tcl-picture__image {
    width: 100%;
  }
}

.tcl-article-heading__media {
  display: flex;
  margin-bottom: 25px;
}

/* BREAKPOINT < MD */
@include media-breakpoint-down(md) {
  .tcl-article-heading {
    .badge {
      margin-bottom: 27px;
    }
  }

  .tcl-article-heading__subtitle {
    h2 {
      margin-bottom: 18px;
    }
  }

  .tcl-article-heading__date {
    margin-bottom: 21px;
  }
}

/* BREAKPOINT < SM */
@include media-breakpoint-down(sm) {
  .tcl-article-heading {
    .container {
      &:nth-child(2) {
        padding: 0;

        .tcl-article-heading__content {
          margin: 0;
        }
      }

      .tcl-article-heading__label {
        right: 0;
        margin-bottom: 15px;
      }
    }
  }

  .tcl-article-heading__image {
    padding: 0;
  }
}
