@import '../../stylesheets/common';

.tcl-article-full-text {
  .tcl-article-full-text__col {
    @include make-col-ready();
    @include make-col(12);

    ol,
    ul {
      padding-left: 18px;

      li {
        padding-left: 18px;
      }
    }

    blockquote {
      padding-left: 28px;

      p {
        margin: 0;
        padding: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    h3 {
      @include heading-h2();
    }

    h4 {
      @include heading-h3();
    }

    h5 {
      @include heading-h4();
    }

    h6 {
      @include heading-h5();
    }
  }

  &.tcl-article-full-text--large-image {
    .tcl-article-full-text__col {
      @include make-col-ready();
      @include make-col(6);
      @include make-col-offset(3);

      .tcl-picture {
        margin-left: -17.5%;
        margin-right: -17.5%;
      }

      blockquote {
        padding-left: 28px;

        p {
          margin: 0;
          padding: 0;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.tcl-article-full-text__content {
  &,
  p,
  ol,
  ul,
  li,
  span {
    color: $dark;
  }

  p {
    margin-bottom: 20px;
  }

  blockquote {
    font-size: rem(20px);
    line-height: rem(28px);
    letter-spacing: rem(-0.38px);
    color: $dark;
  }

  img {
    max-width: 100%;
  }

  iframe {
    width: 100%;
  }

  .tcl-picture,
  iframe,
  .fluid-width-video-wrapper {
    margin: 40px 0;
  }

  .fluid-width-video-wrapper iframe {
    margin: 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $gray-800;
  }

  h2,
  h3,
  h4 {
    margin: 20px 0;
  }

  h5,
  h6 {
    margin: 52px 0;
  }
}

/* BREAKPOINT < MD */
@include media-breakpoint-down(md) {
  .tcl-article-full-text {
    &.tcl-article-full-text--large-image {
      .tcl-article-full-text__col {
        @include make-col-ready();
        @include make-col(12);
        @include make-col-offset(0);

        .tcl-picture {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

/* BREAKPOINT < SM */
@include media-breakpoint-down(sm) {
  .tcl-article-full-text {
    &,
    &.tcl-article-full-text--large-image {
      .container {
        padding: 0;
      }

      .tcl-article-full-text__content {
        margin: 0;
      }

      .tcl-article-full-text__col {
        padding: 0 32px;

        .tcl-picture {
          margin-left: -32px;
          margin-right: -32px;
        }

        blockquote {
          width: calc(100% - 32px);
        }
      }
    }
  }
}
