@import '../../stylesheets/common';

.tcl-footer__first-row {
  background-color: #686665;
  padding: 36px 0;

  .tcl-footer__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -39px;
      left: -1px;
      height: 78px;
      width: 2px;
      background-color: #595959;
    }

    p {
      margin: 0;
      font-family: $font-family-sans-serif;
      font-weight: normal;
      font-size: rem(16px);
      letter-spacing: rem(-0.3px);
      line-height: rem(24px);
      text-transform: none;
      color: $white;
    }

    a {
      font-family: $font-family-sans-serif;
      font-weight: normal;
      font-size: rem(16px);
      letter-spacing: rem(-0.3px);
      line-height: rem(24px);
      text-transform: none;
      color: $white;
      padding: 0;
      margin: 0;

      &:hover,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: $white;
      }
    }

    svg {
      color: $white;
      max-width: 100%;
      width: 280px;
      height: 60.16px;
    }
  }

  .tcl-footer__title {
    @include heading-h6();

    color: $white;
    margin-bottom: 8px;
  }

  .tcl-footer__list-social {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    margin: 8px 0 0;

    li {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    a {
      display: block;
    }

    svg {
      color: #1c1c1c;
      width: 38px;
      height: 38px;
    }

    @media screen and (max-width: 413px) {
      li {
        margin-right: 7px;
        margin-bottom: 7px;
      }

      svg {
        width: 29px;
        height: 29px;
      }
    }
  }
}

.tcl-footer__second-row {
  background-color: $gray-800;
  padding: 30px 0;

  .tcl-footer__col {
    display: flex;

    &:first-child {
      padding-left: 25px;

      @include media-breakpoint-down(xs) {
        margin-bottom: 20px;
      }
    }
  }

  a {
    @include text-14();

    margin-right: 20px;
    font-family: $font-family-serif;
    color: $white;
    text-decoration: none;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $white;
    }
  }
}

/* BREAKPOINT <= LG (TABLET AND SMALLER) */
@include media-breakpoint-down(lg) {
  .tcl-footer__first-row {
    padding: 60px 0;

    .row {
      margin: 0;
    }

    .tcl-footer__col {
      margin-bottom: 63px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .tcl-footer__second-row {
    .row {
      margin: 0 15px;
    }

    .tcl-footer__col {
      padding-left: 25px;
    }
  }
}
