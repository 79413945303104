@import "../../stylesheets/common";

.table__title {
  @include heading-h4();
}

.tcl-table {
  thead {
    tr {
      background-color: $secondary;
      color: $white;

      th {
        font-family: $font-family-serif;
        font-size: rem(15px);
        letter-spacing: rem(-0.28px);
        line-height: rem(18px);
        vertical-align: middle;
        border-right: 1px solid #dee2e6;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  tbody {
    tr {
      color: $dark;
      background-color: $white;

      &:nth-of-type(odd),
      &:nth-of-type(even) {
        background-color: $white;
      }

      &.striped-row {
        background-color: #f7f7f7;
      }

      td {
        vertical-align: middle;
        border-right: 1px solid #dee2e6;
        font-size: rem(15px);
        letter-spacing: rem(-0.28px);
        line-height: rem(24px);

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}

/* BREAKPOINT < XS */
@include media-breakpoint-down(xs) {
  .container--splitted-layout {
    .tcl-table thead tr th:first-child {
      left: 0 !important;
    }

    .tcl-table tbody tr td[rowspan="2"] {
      left: 0;
    }

    .tcl-table tbody tr.table__footer-pagination {
      left: 0;
      width: 100%;
      max-width: 100%;
    }
  }

  .table__title {
    font-size: 24px;
    letter-spacing: -0.3px;
    line-height: 28px;
  }

  .tcl-table {
    thead {
      tr {
        display: flex;
      }

      th {
        background-color: #002862;
        min-height: 74px;
        display: inline-flex;
        align-items: center;
      }
    }

    tbody {
      padding-bottom: 60px;

      tr {
        display: flex;

        td {
          background-color: $white;
          border-right: 1px solid #dee2e6;
          border-bottom: 0;
          border-top: 1px solid #dee2e6;

          &:last-child {
            border-right: 0;
            height: auto;
            display: inline-flex;
            align-items: center;
          }

          &[rowspan="2"] {
            display: flex;
            align-items: center;
            border-top: 1px solid #dee2e6;
            border-bottom: 0;
            left: 14px;
          }
        }

        &.striped-row {
          td {
            background-color: #f7f7f7;
          }
        }

        &:first-child {
          td {
            border-top: 0;
          }
        }

        &:last-child {
          td {
            &[rowspan="2"] {
              border-bottom: 1px solid #dee2e6;
            }
          }
        }

        &.table__footer-pagination {
          background-color: $white;
          border-right: 1px solid #dee2e6;
          position: absolute;
          left: 14px;

          td {
            background-color: $white;
          }

          .table__page {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 1.5em 0;

            span {
              height: 7px;
              width: 41px;
              opacity: 0.59;
              background-color: #a3a3a2;
              margin-right: 7px;

              &.active {
                background-color: #202020;
                opacity: 1;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    tr {
      td {
        display: inline-flex;
        background-color: $white;
      }
    }
  }
}
