@import '../../stylesheets/common';

.tcl-context-heading {
  position: relative;
  padding: 20px 0;
  min-height: 200px;
  display: flex;
  align-items: center;

  .container {
    height: 100%;
    display: flex;
  }
}

.tcl-context-heading__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tcl-context-heading__content {
  margin: 0;
  align-items: center;
  position: relative;
  z-index: 99;
}

.tcl-context-heading__icon {
  display: flex;
  justify-content: center;

  svg {
    width: 73px;
    min-width: 50px;
    height: 65px;
  }
}

.tcl-context-heading__head {
  > * {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    &::after {
      content: '';
      position: absolute;
      height: 111px;
      width: 1px;
      top: 50%;
      margin-top: -55.5px;
      border: 1px dashed #eb7d01;

      /* BREAKPOINT < SM */
      @include media-breakpoint-down(sm) {
        height: 52px;
        margin-top: -26px;
        left: 25px;
      }
    }
  }
}

.tcl-context-heading__occhiello {
  font-family: $font-family-serif;
  font-weight: 300;
  letter-spacing: rem(-0.3px);
  font-size: rem(24px);
  line-height: rem(28px);
  margin: 0;
  padding-left: 30px;
  opacity: 0.5;
  color: #fff;
}

.tcl-context-heading__title {
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.47px);
  font-size: rem(28px);
  line-height: rem(32px);
  margin-bottom: 0;
  padding-left: 30px;
  color: #fff;
}

.tcl-context-heading__abstract {
  > * {
    font-family: $font-family-serif;
    font-weight: 300;
    letter-spacing: rem(-0.3px);
    font-size: rem(16px);
    line-height: rem(22px);
    display: none;
    margin: 0;
    color: #fff;
  }
}

/* BREAKPOINT >= MD */
@include media-breakpoint-up(md) {
  .tcl-context-heading {
    height: 200px;
    padding: 55px 0;
  }

  .tcl-context-heading__icon {
    justify-content: flex-end;
  }
}

/* BREAKPOINT >= LG */
@include media-breakpoint-up(lg) {
  .tcl-context-heading__head {
    &::after {
      height: 111px;
      margin-top: -55.5px;
    }
  }

  .tcl-context-heading__content {
    margin-right: -15px;
    margin-left: -15px;
  }

  .tcl-context-heading__occhiello,
  .tcl-context-heading__title {
    padding-left: 26px;
  }

  .tcl-context-heading__abstract {
    > * {
      display: inline-block;
    }

    p {
      margin: 0;
    }
  }
}
