@import "../../stylesheets/common";

.tcl-slide-nav {
  .sidebar-wrapper {
    padding: 110px 0 24px;

    .sidebar-linklist-wrapper {
      .link-list-wrapper {
        .link-list {
          li {
            .list-item,
            .list-item.active {
              border: 1px solid #979797;
              background-color: $white;
              padding: 15.5px 14.5px;
              text-decoration: unset;
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:focus {
                box-shadow: unset;
              }

              span {
                font-family: $font-family-base;
                letter-spacing: rem(-0.3px);
                font-size: rem(16px);
                line-height: rem(24px);
                color: $dark;
                padding: 0;
                text-decoration: unset;
              }

              .svg-icon--arrow-link {
                color: $dark;
                transform: rotate(90deg);
              }
            }
          }

          > li {
            > .list-item.active,
            > .list-item.focus--mouse,
            > .list-item.clicked {
              position: relative;
              background-color: rgba(0, 40, 98, 0.16);

              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 7px;
                background-color: $secondary;
              }
            }

            li {
              .list-item,
              .list-item.active {
                background-color: #f7f7f7;
              }
            }

            > .list-item,
            > .list-item.active {
              span {
                font-weight: 900;
              }
            }
          }
        }

        .link-sublist {
          padding-left: 6px;
        }
      }
    }
  }
}

.tcl-slide-nav--mobile {
  padding: 0 15px;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 32px;
  }

  .btn-primary {
    background-color: #a3a3a2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: unset;
    font-family: $font-family-serif;
    font-size: rem(20px);
    line-height: rem(24px);
    letter-spacing: rem(-0.3px);
    padding: rem(13px) rem(22px);
    border-radius: 6px;

    svg {
      width: 20px;
      height: 20px;
      margin-left: 30px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .modal {
    .modal-dialog {
      margin-right: 40px;

      .modal-content {
        .modal-body {
          flex: unset;
          padding: 0 40px 0 30px;
        }

        .modal-footer {
          padding: 21px 30px;
        }
      }
    }
  }

  .sidebar-wrapper {
    padding: 120px 0 19px;
  }

  .link-list-wrapper ul {
    margin-bottom: 0;
  }
}

.tcl-slide-nav--desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
