@import '../../stylesheets/common';

.tcl-banner-reel {
  padding: 52px 0;
  background-color: #c7ced4;
}

.tcl-banner-reel__media {
  display: flex;

  picture {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
}

/* BREAKPOINT < MD */
@include media-breakpoint-down(md) {
  .tcl-banner-reel {
    padding: 54px 0 48px;
    background-color: #c7ced4;
  }

  .tcl-banner-reel__image {
    margin-bottom: 14px;
    padding: 0 8px;
  }

  .tcl-banner-reel__content {
    margin: 0 -8px;
  }
}
