
$form-font-size: 16px;
$form-line-height: 24px;
$form-letter-spacing: -0.3px;
$form-text-color: color('lead');
$form-group-margin-bottom: 30px;
$form-input-height: 45px;
$form-input-x-padding: 15px;
$form-input-y-padding: 6px;
$form-input-border: 1px solid #a3a3a2;
$form-input-border-radius: 4px;

/* Mixins */

@mixin make-form-label() {
  top: 11px;
  padding: 0 $form-input-x-padding;
  font-family: $font-family-sans-serif;
  font-size: rem($form-font-size);
  line-height: rem($form-line-height);
  letter-spacing: rem($form-letter-spacing);
  font-weight: normal;
  color: $form-text-color;
}

/* Basic */

.form-control {
  font-size: rem($form-font-size);
  line-height: rem($form-line-height);
  letter-spacing: rem($form-letter-spacing);
  font-weight: normal;
  color: $form-text-color;
}

input,
textarea {
  &.form-control {
    border: $form-input-border;
    border-radius: $form-input-border-radius;
    padding: $form-input-y-padding $form-input-x-padding;
    height: $form-input-height;
    font-weight: normal;
  }
}

.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-group label {
  @include make-form-label();
}

.form-group label.active {
  transform: translateY(-140%);
}

/* Bootstrap select */

.bootstrap-select-wrapper {
  margin-bottom: $form-group-margin-bottom;

  .dropdown.bootstrap-select .btn {
    margin: 0;
    height: $form-input-height;
    border: $form-input-border;
    border-radius: $form-input-border-radius;
    text-transform: none;
  }

  button {
    padding: $form-input-y-padding $form-input-x-padding;
    font-size: rem($form-font-size);
    line-height: rem($form-line-height);
    font-weight: normal;

    .filter-option {
      &::after {
        display: none;
      }

      .filter-option-inner {
        width: 100%;
      }

      .filter-option-inner-inner {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &.dropdown-toggle::after {
      display: block;
      position: relative;
      top: 2px;
      margin-left: 20px;
      flex-shrink: 0;
      width: 8px;
      height: 12px;
      transform: rotate(90deg);
      transform-origin: center;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjhweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgOCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4NCiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4NCiAgICA8dGl0bGU+QDF4QXJyb3c8L3RpdGxlPg0KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPg0KICAgIDxnIGlkPSJJQ09ORSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxnIGlkPSJJQ09ORS1TRVJWSVpJIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjc4LjAwMDAwMCwgLTExNjAuMDAwMDAwKSIgZmlsbD0iY3VycmVudENvbG9yIj4NCiAgICAgICAgICAgIDxnIGlkPSJDb250YWluZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIzNS4wMDAwMDAsIDExNTIuMDAwMDAwKSI+DQogICAgICAgICAgICAgICAgPGcgaWQ9Ii1BcnJvd3MtLy1TbWFsbC0vLUZ3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0My4wMDAwMDAsIDEuMDAwMDAwKSI+DQogICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJMaW5lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzLjg5NjEwNCwgMTMuMzE4ODAzKSByb3RhdGUoLTkwLjAwMDAwMCkgdHJhbnNsYXRlKC0zLjg5NjEwNCwgLTEzLjMxODgwMykgIiBwb2ludHM9IjguMDI2NjYzMzIgOS45OTA4MzI4NSA5LjI4OTM1NCAxMS4yNTM1MjM1IDMuODk2MTAzOSAxNi42NDY3NzM2IC0xLjQ5NzE0NjIxIDExLjI1MzUyMzUgLTAuMjM0NDU1NTI1IDkuOTkwODMyODUgMy44OTYxMDM5IDE0LjEyMTM5MjMiPjwvcG9seWdvbj4NCiAgICAgICAgICAgICAgICA8L2c+DQogICAgICAgICAgICA8L2c+DQogICAgICAgIDwvZz4NCiAgICA8L2c+DQo8L3N2Zz4NCg==);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border: none;
    }
  }

  > label {
    @include make-form-label();

    font-size: 80%;
    transform: translateY(-140%);
  }

  .bootstrap-select {
    &.dropdown .dropdown-menu li a {
      font-size: $form-font-size;
      padding: 8px 24px;
      color: $form-text-color;
      white-space: inherit;
    }

    .dropdown-toggle .filter-option {
      display: flex;
    }

    &.dropdown .bs-actionsbox .btn-block {
      padding: 0;

      .bs-select-all,
      .bs-deselect-all {
        margin: 0;
        padding: 4px 24px;
        width: 100%;
        height: auto;
        text-align: left;
        font-size: 16px;
        border: none;
        color: #455b71;
      }
    }
  }
}

.bootstrap-select-wrapper--hide-select-all .dropdown.bootstrap-select .bs-actionsbox .btn-block .bs-select-all {
  display: none;
}

.bootstrap-select-wrapper--hide-deselect-all .dropdown.bootstrap-select .bs-actionsbox .btn-block .bs-deselect-all {
  display: none;
}

/* Toggles */

.toggles label {
  position: relative;
  top: 0;
  height: $form-input-height;
  line-height: $form-input-height;

  input[type='checkbox'] + .lever {
    margin-top: 14px;
  }
}

/* Checkbox & Radio buttons */

.form-check {
  margin-top: 4px;

  [type='checkbox'] + label,
  [type='radio'] + label {
    height: auto;
    padding-left: 32px;
    font-family: $font-family-sans-serif;
    font-weight: normal;
    letter-spacing: rem(-0.3px);
    font-size: rem(16px);
    line-height: rem(24px);

    @include media-breakpoint-up(lg) {
      line-height: rem(28px);
    }
  }

  [type='radio'] {
    + label::after,
    + label::before {
      margin: 0;
      //top: 50%;
      //margin-top: -9px;
      width: 18px;
      height: 18px;
      border-width: 1px;
    }

    &:not(:checked) + label {
      &::after,
      &::before {
        border-color: black;
        background: white;
      }
    }

    &:disabled:not(:checked) + label {
      &::after,
      &::before {
        border-color: black;
        opacity: 0.5;
      }
    }
  }

  [type='checkbox'] {
    + label::after {
      width: 18px;
      height: 18px;
      border-width: 1px;
    }

    &:not(:checked) + label::after {
      border-color: black;
    }

    &:checked + label::before {
      width: 7px;
      height: 11px;
    }
  }
}

/* Modifier: no floating label */

.form-group--no-floating-label {
  label {
    &,
    &.active {
      position: static;
      transform: none;
      font-size: rem($form-font-size);
    }
  }
}
