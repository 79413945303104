@import '../../stylesheets/common';

.tcl-banner-service {
  margin-top: 60px;
  margin-bottom: 70px;
}

.tcl-banner-service__inner {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 6px;
  background-color: $secondary;
}

.tcl-banner-service__content {
  margin: 0;
}

.tcl-banner-service__title {
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.5px);
  font-size: rem(32px);
  line-height: rem(40px);
  margin-bottom: 0;
  padding-left: 33px;
  color: #fff;
}

.tcl-banner-service__abstract {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  letter-spacing: rem(-0.3px);
  font-size: rem(16px);
  line-height: rem(24px);
  margin: 0;
  color: #fff;

  p {
    margin: 0;
  }
}

.tcl-banner-service__cta {
  justify-content: center;
  align-items: center;
  display: flex;

  .btn {
    white-space: nowrap;
  }
}

/* BREAKPOINT < MD */
@include media-breakpoint-down(md) {
  .tcl-banner-service {
    margin-top: 52px;
    margin-bottom: 52px;
  }

  .tcl-banner-service__inner {
    padding-top: 27px;
    padding-bottom: 27px;
  }

  .tcl-banner-service__title {
    padding: 0 38px;
    margin-bottom: 25px;
  }

  .tcl-banner-service__abstract {
    padding: 0 38px;
    margin-bottom: 20px;
  }

  .tcl-banner-service__cta {
    padding: 0 38px;

    a {
      width: 100%;
    }
  }
}

/* BREAKPOINT < SM */
@include media-breakpoint-down(sm) {
  .tcl-banner-service__cta {
    .btn {
      margin-right: 0;
    }
  }
}
