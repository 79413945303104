@import '../../stylesheets/common';

$header-background: #fff;
$header-subnav-background: #fff;
$header-separator-color: #d0d0d0;
$header-link-primary-color: #1c2024;
$header-link-secondary-color: #858585;
$header-link-highlight-color: color('orange');

.tcl-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $zindex-header;
  background: $header-background;
}

.tcl-header,
.skiplinks-placeholder {
  + main {
    @include media-breakpoint-down(lg) {
      padding-top: $header-height-mobile;
    }

    @include media-breakpoint-up(xl) {
      padding-top: $preheader-height-desktop + $header-height-desktop;
    }
  }
}

/* pre header */

.tcl-header__pre {
  display: none;
}

@include media-breakpoint-up(xl) {
  // .tcl-header::after {
  //   content: '';
  //   display: block;
  //   width: 100%;
  //   height: 24px;
  //   position: absolute;
  //   bottom: -24px;
  //   left: 0;
  //   background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f4f4f4 100%);
  // }

  .tcl-header__pre {
    display: block;
    background: #f7f7f7;
  }

  .tcl-header__pre__nav {
    display: flex;
  }

  .tcl-header__pre__nav__ul {
    display: flex;
    list-style: none;
    margin: 0 0 0 auto;
    padding: 0;

    > li {
      > a {
        display: block;
        padding: 7px 18px;
        text-decoration: none;
        color: $header-link-primary-color;
        font-size: rem(16px);
        line-height: rem(34px);
        letter-spacing: rem(-0.3px);

        &:hover,
        &.current {
          text-decoration: underline;
        }
      }

      &:last-child {
        margin-right: -7px;
      }
    }
  }
}

/* navbar */
.tcl-header__navbar > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* logo */
.tcl-header__brand {
  display: block;
  text-decoration: none;

  &,
  &:hover {
    color: $header-link-primary-color;
    transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}

.tcl-header__navbar {
  position: relative;
  z-index: 1;
}

/* main menu container */
.tcl-header__menu {
  .nav-item {
    &.nav-item--shop > a {
      text-transform: uppercase;
      color: $header-link-highlight-color;
    }

    &.nav-item--secondary > a {
      color: $header-link-secondary-color;
    }

    &.nav-item--spacer {
      height: 43px;
    }
  }
}

/* header search */
.tcl-header__search {
  display: none;
}

@include media-breakpoint-up(xl) {
  .tcl-header__search {
    display: block;
    width: 275px;
  }

  .tcl-header__search__opener {
    display: block;
    position: relative;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .tcl-header__search__input-fake {
    display: block;
    width: auto;
    height: 40px;
    background: #e8e8e8;
    border-radius: 18px;
  }

  .tcl-header__search__btn-fake {
    position: absolute;
    top: 0;
    right: 5px;
    display: block;
    padding: 9px;
    margin: 0;
    background: transparent;
    border: none;
    z-index: 4;

    .svg-icon--search-thin {
      display: block;
    }
  }
}

.tcl-header__actions {
  display: flex;
  align-items: center;
}

.tcl-header__actions__item {
  position: relative;
  margin: 0 15px;

  &:last-child {
    margin-right: 0;
  }

  .svg-icon {
    color: $header-link-primary-color;
  }

  @include media-breakpoint-down(lg) {
    margin: 0 10px;
  }
}

.tcl-header__actions__item__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0 2px;
  top: -11px;
  right: -12px;
  min-width: 23px;
  height: 22px;
  border: 2px solid #000;
  background-color: #ff0;
  color: #000;
  font-size: rem(12px);
  line-height: rem(32px);
  letter-spacing: rem(-0.05px);
  font-weight: bold;
  border-radius: 25px;
}

/* STATE: menu open */
.header-menu-open {
  overflow: hidden;
  position: relative;

  .tcl-header__menu {
    visibility: visible;
  }
}

.tcl-header__nav__back {
  display: none;
}

/* BREAKPOINT < XL (Hamburgher Menu) */
@include media-breakpoint-down(lg) {
  .tcl-header__menu {
    position: fixed;
    z-index: 2020;
    top: $header-height-mobile;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 80px;
    visibility: hidden;
    overflow: auto;
    background: #f7f7f7;
  }

  .tcl-header__nav__back {
    position: fixed;
    top: 0;
    left: 28px;
    width: 190px;
    z-index: 2020;
    height: $header-height-mobile - 1px;
    background: $header-background;
    border: none;
    text-align: left;

    .svg-icon {
      transform: rotate(180deg);
    }

    .tcl-header__nav--nav-open & {
      display: block;
    }
  }

  .tcl-header__navbar {
    box-shadow: inset 0 -1px 0 #e2e2e2;

    > .container {
      max-width: none;
      height: $header-height-mobile;
      padding-left: 32px;
      padding-right: 32px;
    }

    .svg-icon--logo-cliclavoro-desktop {
      width: 128px;
    }

    .nav-menu {
      display: block;
      padding: 0;
      margin: 0;
      list-style: none;
      box-shadow: inset 0 17px 0 0 #f7f7f7;
      padding-top: 17px;
    }

    .nav-item {
      border-bottom: solid 1px $header-separator-color;
      position: relative;

      &:first-child {
        border-top: solid 1px $header-separator-color;
      }

      > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        padding: 10px 31px;
        min-height: 59px;
        font-size: rem(18px);
        line-height: rem(25px);
        letter-spacing: rem(-0.3px);
        font-weight: 500;
        text-decoration: none;
        background: #fff;

        &:hover {
          text-decoration: none;
        }

        &.current {
          background: #eee;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background: transparent;
        }
      }

      &.open {
        display: block;
        position: fixed;
        z-index: 2020;
        top: $header-height-mobile;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        background: $header-subnav-background;
        border-top: none;

        > a {
          padding-top: 27px;
          color: #455b71;
          background: #f7f7f7;
          font-size: 25px;
          font-size: rem(25px);
          line-height: rem(32px);
          letter-spacing: rem(-0.4px);
          font-weight: 500;
          border-top: none;
          border-bottom: solid 5px $header-link-highlight-color;

          .svg-icon {
            display: none !important;
          }
        }
      }
    }

    .sub-nav {
      display: none;

      &.open {
        display: block;
      }
    }

    .sub-nav__group-list,
    .tcl-header__pre__nav__ul {
      display: block;
      margin: 0;
      padding: 0;
      list-style-type: none;

      > li {
        border-bottom: 1px solid $header-separator-color;

        > a {
          display: block;
          padding: 14px 30px;
          font-size: rem(18px);
          line-height: rem(28px);
          font-weight: normal;
          letter-spacing: 0;
          color: #202020;
          background: #fff;
          text-transform: none;
          text-decoration: none;

          &.current {
            background: #eee;
          }
        }
      }
    }

    .tcl-header__pre__nav {
      border-top: solid 17px #f7f7f7;
      border-bottom: solid 33px #fff;
    }

    .tcl-header__pre__nav__ul {
      border-top: solid 1px #dbdbdb;

      > li > a {
        font-size: rem(16px);
      }
    }

    .sub-nav__col {
      &:last-child {
        padding-bottom: 80px;
      }

      + .sub-nav__col {
        border-top: solid 17px #f7f7f7;

        .sub-nav__group {
          border-top: solid 1px #dbdbdb;
        }

        .sub-nav__group-list > li > a {
          background: #f7f7f7;
        }
      }
    }
  }

  /* search opener/closer button */
  .tcl-header__actions__item--search {
    display: block;
    position: relative;
    right: -8px;
    background: none;
    border: none;
    padding: 6px;
    margin-left: auto;
    width: 42px;
    height: 34px;
    color: $header-link-primary-color;

    .svg-icon {
      margin: 0 auto;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: $btn-focus-box-shadow;
    }
  }

  /* menu opener/closer button */
  .tcl-header__actions__item--toggler {
    display: block;
    position: relative;
    right: -8px;
    background: none;
    border: none;
    padding: 6px;
    margin-left: auto;
    width: 42px;
    height: 34px;
    color: $header-link-primary-color;

    .svg-icon {
      margin: 0 auto;
    }

    .svg-icon--close {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: $btn-focus-box-shadow;
    }

    .header-menu-open & {
      .svg-icon--burger {
        display: none;
      }

      .svg-icon--close {
        display: block;
      }
    }
  }

  .tcl-header__nav {
    display: none;

    .header-menu-open & {
      display: block;
    }
  }
}

/* BREAKPOINT >= XL (Desktop Menu) */
@include media-breakpoint-up(xl) {
  .tcl-header__brand {
    margin-top: -30px;
  }

  .tcl-header__navbar > .container {
    height: $header-height-desktop;
  }

  .tcl-header__actions__item--toggler {
    display: none;
  }

  /* main menu container */
  .tcl-header__menu {
    /* mega menu list */
    .nav-menu {
      display: flex;
      position: relative;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    /* a top level navigation item in the mega menu */
    .nav-item {
      position: relative;
      list-style: none;
      display: block;
      padding: 0;
      margin: 0;
    }

    /* first descendant link within a top level navigation item */
    .nav-item > a {
      position: relative;
      display: block;
      padding: 20px 17px;
      color: $header-link-primary-color;
      font-size: rem(16px);
      line-height: rem(34px);
      letter-spacing: rem(-0.3px);
      font-weight: 500;
      border-width: 1px;
      text-decoration: none;
      white-space: nowrap;

      &[aria-expanded] {
        padding-right: 27px;
      }

      .svg-icon--arrow-link {
        position: absolute;
        top: 50%;
        margin-top: -5px;
        right: 10px;
        width: 10px;
        height: 12px;
        transform: rotate(90deg);
      }

      &:hover {
        text-decoration: none;
      }

      &.current,
      &:hover {
        &::after {
          background: $header-link-highlight-color;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: transparent;
      }
    }

    /* focus/open states of first descendant link within a top level navigation item */
    .nav-item > a:focus,
    .nav-item > a.open {
      color: $header-link-primary-color;
      outline: none;
    }

    /* open state of first descendant link within a top level
    navigation item */
    .nav-item > a.open {
      border-bottom: none;
      z-index: 1;
    }

    .nav-item > a.open,
    .nav-item > a.current {
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: $header-link-highlight-color;
      }
    }

    /* sub-navigation panel */
    .sub-nav {
      @include clearfix;

      display: flex;
      visibility: hidden;
      position: absolute;
      top: auto;
      left: 0;
      width: auto;
      background: $header-subnav-background;
      box-shadow: 0 12px 24px 0 rgba(160, 160, 160, 0.5);
    }

    .nav-item:last-child .sub-nav {
      left: -165px;
    }

    .sub-nav__col {
      float: left;
      width: 295px;
    }

    .sub-nav__group {
      padding: 22px 28px;
      opacity: 0;
    }

    /* list of items within sub-navigation panel */
    .sub-nav__group-list {
      display: block;
      margin: 0;
      padding: 0;

      /* list item within sub-navigation panel */
      > li {
        display: block;
        list-style-type: none;
        margin: 0;
        padding: 0;
        border-top: 1px solid $header-separator-color;

        &:first-child {
          border-top: 0;
        }

        > a {
          display: block;
          padding: 0;
          font-size: rem(14px);
          line-height: rem(33px);
          font-weight: 400;
          letter-spacing: rem(-0.3px);
          color: color('black');
          text-transform: none;
          text-decoration: none;

          &:hover,
          &.current {
            text-decoration: underline;
          }
        }
      }
    }

    /* second column */
    .sub-nav__col + .sub-nav__col {
      background: #f7f7f7;

      .sub-nav__group-list > li > a {
        padding: 11px 0;
        font-size: rem(19px);
        line-height: rem(24px);
        letter-spacing: rem(-0.41px);
        color: color('lead');
      }
    }

    /* sub-navigation panel open state */
    .sub-nav.open {
      visibility: visible;
      border: solid 1px #979797;

      .sub-nav__group {
        opacity: 1;
      }
    }
  }

  /* actions */
  .tcl-header__actions {
    display: none;
  }
}
