
.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  @include heading-h2();
}

.modal .close {
  opacity: 1;
}

.modal-backdrop {
  background-color: $black;

  &.show {
    opacity: 0.7;
  }
}
