@import '../../stylesheets/common';

.tcl-highlight-article {
  position: relative;
}

.tcl-highlight-article__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tcl-highlight-article__occhiello {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: rem(13px);
  line-height: rem(20px);
  letter-spacing: rem(-0.28px);
  color: $dark;
  margin-bottom: 8px;
}

.tcl-highlight-article__title {
  @include heading-h3();

  color: #202020;
  margin-bottom: 5px;
}

.tcl-highlight-article__abstract {
  font-family: $font-family-base;
  font-size: rem(18px);
  line-height: rem(24px);
  letter-spacing: rem(-0.3px);
  color: #202020;
  margin-bottom: 12px;
}

.tcl-highlight-article__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

/* Modifier: overlay hover */

.tcl-highlight-article__image {
  .tcl-picture {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
  }

  .tcl-picture__image {
    transition: opacity 0.15s linear, transform 0.3s;
  }
}

.tcl-highlight-article__title,
.tcl-highlight-article__abstract,
.tcl-highlight-article__cta {
  transition: opacity 0.3s;
}

.tcl-highlight-article--hover {
  .tcl-highlight-article__image .tcl-picture__image {
    transform: scale(1.1);
  }

  .tcl-highlight-article__title,
  .tcl-highlight-article__abstract,
  .tcl-highlight-article__cta {
    opacity: 0.7;
  }
}

/* BREAKPOINT < MD */
@include media-breakpoint-down(md) {
  .tcl-highlight-article {
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .tcl-highlight-article__content {
    margin: 0;
  }

  .tcl-highlight-article__image {
    margin-bottom: 26px;
    padding: 0;
  }

  .tcl-highlight-article__info {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 720px;
  }
}

/* BREAKPOINT < XS */
@include media-breakpoint-down(xs) {
  .tcl-highlight-article__info {
    padding-right: 32px;
    padding-left: 32px;
    max-width: 100%;
  }

  .tcl-highlight-article__occhiello {
    margin-bottom: 0;
  }
}
