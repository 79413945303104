@import '../../stylesheets/common';

.tcl-breadcrumb {
  width: 120%;
  margin-top: calc(30px - 0.5em);
  margin-bottom: 44px;

  .breadcrumb-container .breadcrumb {
    margin: 0;

    .breadcrumb-item {
      a,
      & {
        font-family: $font-family-serif;
        font-size: rem(13px);
        line-height: rem(20px);
        letter-spacing: rem(-0.28px);
        font-weight: 300;
        color: $dark;
        text-decoration: unset;
      }

      &.active {
        font-weight: 700;

        a {
          font-weight: 700;
          pointer-events: unset;
        }
      }
    }
  }

  /* BREAKPOINT < MD */
  @include media-breakpoint-down(md) {
    width: 100%;
    margin-bottom: 20px;
  }
}
