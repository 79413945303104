.pagination {
  $color: $lead;
  $border-radius: 4px;
  $border-color: #d8d8d8;
  $current-color: color('white');
  $current-background: color('blue');

  margin: 0;

  .page-item {
    margin: 0;
    color: $color;

    .page-link {
      color: $color;
      min-width: rem(40px);
      height: rem(36px);
      font-size: 16px;
      line-height: 1.5;
      font-weight: normal;
      border-radius: 0;
      border: 1px solid $border-color;

      &[aria-current] {
        color: $current-color;
        background: $current-background;
        border: 1px solid $border-color;
      }

      &:hover {
        color: color('white');
        background-color: color-hover($current-background);
      }

      &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($current-background), $current-background, 15%), 0.5);
      }

      .svg-icon {
        width: 13px;
        height: 13px;
      }
    }

    &:first-child .page-link {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child .page-link {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}
