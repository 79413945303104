@import '../../stylesheets/common';

.tcl-search-heading {
  background-color: $secondary;
  padding: 32px 0;
  height: 200px;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    padding: 50px 0;
  }
}

.tcl-search-heading__label {
  display: block;
  margin: 0;
  font-family: $font-family-serif;
  font-size: rem(24px);
  line-height: rem(28px);
  letter-spacing: rem(-0.3px);
  font-weight: 300;
  color: #fff;
  opacity: 0.5;
}

.tcl-search-heading__field {
  position: relative;
}

input[type='search'].tcl-search-heading__input {
  padding: 5px 0;
  height: 42px;
  font-family: $font-family-serif;
  font-size: rem(28px);
  line-height: rem(32px);
  letter-spacing: rem(-0.3px);
  color: #fff;
  border: 0;
  border-bottom: solid 2px rgba(255, 255, 255, 0.5);
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  @include media-breakpoint-up(lg) {
    padding: 5px 0;
    height: 60px;
    font-size: rem(40px);
    line-height: rem(48px);
    font-weight: 900;
  }
}

.tcl-search-heading__input:focus {
  outline: none;
}

.tcl-search-heading__button {
  display: block;
  position: absolute;
  right: 0;
  bottom: 8px;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: #fff;

  .svg-icon {
    width: 25px;
    height: 25px;
    opacity: 0.5;
  }

  @include media-breakpoint-up(lg) {
    bottom: 10px;

    .svg-icon {
      width: 48px;
      height: 48px;
    }
  }
}
