@import '../../stylesheets/common';

.tcl-related-source__title {
  display: block;
  font-family: $font-family-serif;
  font-weight: 700;
  letter-spacing: rem(-0.3px);
  font-size: rem(24px);
  line-height: rem(28px);
  color: $gray-800;
  margin-bottom: 21px;
  padding-bottom: 6px;
  border-bottom: 1px solid #979797;
}

.tcl-related-source__list-sources {
  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 5px;
    }
  }
}

.tcl-related-source__cta {
  display: flex;
  align-items: center;
  text-decoration: unset;

  &:hover {
    text-decoration: unset;
  }
}

.tcl-related-source__badge {
  display: flex;
  align-items: center;
}

.tcl-related-source__occhiello {
  font-family: $font-family-base;
  font-weight: 300;
  letter-spacing: rem(0.1px);
  font-size: rem(16px);
  line-height: rem(24px);
  color: $dark;
  margin-left: 6px;
}

.container--splitted-layout {
  .tcl-related-source {
    padding: 0;

    /* BREAKPOINT < MD */
    @include media-breakpoint-down(md) {
      padding: 0 15px;
    }

    /* BREAKPOINT < SM */
    @include media-breakpoint-down(sm) {
      .tcl-related-source__content {
        > div {
          padding: 0 17px;
        }
      }
    }

    /* BREAKPOINT < XS */
    @include media-breakpoint-down(xs) {
      padding: 0 32px;
    }
  }
}
