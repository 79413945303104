@import '../../stylesheets/common';

.section-navbar {
  margin-bottom: 25px;
  z-index: 110px;
  background: #f7f7f7;

  .form-group label {
    overflow: visible;
    text-overflow: unset;
    white-space: nowrap;
  }

  .btn-outline-form {
    margin-right: 0;

    .svg-icon {
      position: relative;
      top: -1px;
      left: -2px;
      margin-right: 4px;
    }
  }

  .tag-list + .dropdown {
    margin-left: 30px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 5px;
    margin-bottom: 70px;

    .form-inline {
      flex-flow: nowrap;

      .form-control {
        width: 132px;
        height: 36px;
      }

      .form-group + .form-group {
        margin-left: 25px;
      }
    }

    .bootstrap-select-wrapper {
      display: flex;
      align-items: center;
      margin: 0;

      .dropdown.bootstrap-select .btn {
        min-width: 132px;
        height: 36px;
        background: #fff;
      }

      .dropdown-menu {
        z-index: 700;
      }
    }

    .bootstrap-select .dropdown-menu {
      min-width: 200px;
    }
  }
}

@include media-breakpoint-down(md) {
  .mobile-filters-form {
    .form-group {
      margin-bottom: 22px;
    }

    .form-group--no-floating-label label {
      top: 0;
      margin-top: 30px;
      position: relative;
    }
  }
}

.bootstrap-select-wrapper {
  @include media-breakpoint-down(md) {
    margin-bottom: 22px;
  }

  button.dropdown-toggle .filter-option {
    align-items: center;
  }

  &.calendar-select {
    button.dropdown-toggle {
      padding-left: 45px;

      &::before {
        content: '';
        background-image: url('/static/images/svg/calendar-filter.svg');
        background-repeat: no-repeat;
        background-size: 17px 18px;
        background-position: center;
        position: absolute;
        left: 16px;
        width: 17px;
        height: 18px;
        top: 50%;
        margin-top: -9px;
      }
    }
  }
}

.section-navbar__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;

  .dropdown {
    .btn-dropdown {
      text-transform: unset;
      font-weight: 300;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 73px;
  }
}

.section-navbar__left {
  display: flex;

  @include media-breakpoint-up(lg) {
    .form-group:first-child .bootstrap-select-wrapper > label {
      padding-left: 0;
    }
  }
}

.section-navbar__right {
  @include media-breakpoint-up(lg) {
    display: flex;
    min-width: 0;
    margin-left: auto;
    padding-left: 20px;

    .form-inline {
      &,
      .form-group,
      .bootstrap-select-wrapper,
      .bootstrap-select {
        min-width: 0;
      }
    }
  }
}

.section-navbar__title {
  &,
  h1 {
    font-family: $font-family-serif;
    font-weight: 700;
    letter-spacing: rem(-0.3px);
    font-size: rem(20px);
    line-height: rem(24px);
  }

  h1 {
    display: inline;
    margin-left: 10px;
  }
}

/* Modifier: mob-x-scroll */

@include media-breakpoint-down(lg) {
  .section-navbar--mob-x-scroll {
    overflow-x: auto;
  }
}

/* Modal Filters (per mobile) */

$modal-bottom-initial-transform: translate(0, 100vh) !default;
$modal-bottom-show-transform: none !default;
$modal-bottom-transition: transform 0.3s ease-out !default;

.modal-bottom-open {
  position: fixed;
  overflow: hidden;
  width: 100%;

  .modal {
    overflow-x: hidden;
  }

  .modal-backdrop {
    background-color: #001b42;
  }
}

.modal.modal-bottom .modal-dialog {
  margin: 0;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: none;
  transform: $modal-bottom-initial-transform;
  @include transition($modal-bottom-transition);

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 5px;
    width: 37px;
    border-radius: 3px;
    background-color: #d4d4d4;
  }

  .modal-title {
    font-weight: 700;
    font-size: rem(20px);
    line-height: rem(28px);
    text-align: center;
  }

  .modal-content {
    border-radius: 10px 10px 0 0;

    .modal-header {
      padding: 26px 65px 0;
      justify-content: center;

      .close {
        position: absolute;
        top: 28px;
        right: 28px;
      }
    }

    .modal-body {
      padding: 35px 30px;

      @include media-breakpoint-down(md) {
        padding-top: 12px;
      }
    }
  }
}

.modal.modal-bottom.show .modal-dialog {
  transform: $modal-bottom-show-transform;
}

.modal.modal-bottom:not(.show) {
  opacity: 1;
}

.mobile-filters-form {
  .form-group--no-floating-label label {
    padding: 0;
    margin-bottom: 20px;
    font-family: $font-family-serif;
    font-weight: 700;
    font-size: rem(24px);
    line-height: rem(28px);
    letter-spacing: rem(-0.3px);
    color: color('orange');
  }

  .chip-bar {
    padding: 0 0 30px;
  }

  .chip-bar__list {
    flex-wrap: wrap;
  }
}

.mobile-filters-form__footer {
  margin-top: 60px;

  .btn {
    display: block;
    width: 100%;
    height: 45px;
  }
}
