@import '../../stylesheets/common';

.preview {
  position: relative;
  margin-bottom: 28px;
}

.preview__media {
  display: flex;
  margin-bottom: 24px;

  .tcl-picture {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 16px;
  }
}

.preview__top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .badge {
    margin-right: 10px;
  }
}

.preview__date {
  font-family: $font-family-serif;
  font-weight: 300;
  font-size: rem(13px);
  line-height: rem(20px);
  letter-spacing: rem(-0.28px);
  color: color('lead');
}

.preview__title {
  @include heading-h5();

  margin-bottom: 8px;
  color: color('gray-800');
}

.preview__desc {
  margin-bottom: 8px;
  font-size: rem(16px);
  line-height: rem(24px);
  letter-spacing: rem(-0.3px);
  color: color('lead');
}

.preview__attachments {
  list-style: none;
  padding: 0;
  margin: 5px 0 12px;

  > li {
    margin: 0 0 7px;
    font-size: rem(13px);
    line-height: rem(20px);
    letter-spacing: rem(-0.28px);

    > a:hover {
      text-decoration: none;

      .badge {
        opacity: 0.7;
      }
    }
  }

  .badge {
    margin-right: 10px;
    transition: opacity 0.3s;
  }
}

.preview__expiry {
  margin-bottom: 8px;
  font-size: rem(16px);
  line-height: rem(24px);
  letter-spacing: rem(-0.3px);
  color: color('orange');
}

.preview__labels {
  margin-bottom: 18px;
  font-family: $font-family-serif;
  font-weight: 300;
  font-size: rem(13px);
  line-height: rem(20px);
  letter-spacing: rem(-0.28px);
  color: color('azure-dark');
}

.preview__ctas {
  margin-bottom: 8px;
}

.preview__anchor-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

.preview:not(.preview--layout-search) .preview__main {
  max-width: 578px;
}

/* Modifier: overlay hover */

.preview__media .tcl-picture__image {
  transition: opacity 0.15s linear, transform 0.3s;
}

.preview__body,
.preview__foot {
  transition: opacity 0.3s;

  .mark {
    background-color: #ffdb1c;
  }
}

.preview--anchor-hover {
  .preview__media .tcl-picture__image {
    transform: scale(1.1);
  }

  .preview__body,
  .preview__foot {
    opacity: 0.7;
  }
}

/* Modifier: layout search */

.preview--layout-search {
  @include media-breakpoint-down(md) {
    .preview__media {
      max-width: (275px + 30px);
    }
  }

  @include media-breakpoint-up(lg) {
    align-items: center;
    flex-wrap: nowrap;

    .preview__media,
    .tcl-picture {
      margin-bottom: 0;
    }
  }
}

/* Modifier: layout half */

.preview--layout-half {
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;

    .preview__media,
    .preview__main {
      width: 50%;
    }

    .preview__media {
      padding-right: 15px;
    }

    .preview__main {
      padding-left: 15px;
    }

    .preview__media,
    .tcl-picture {
      margin-bottom: 0;
    }

    .preview__title {
      padding-right: 8%;
    }
  }
}

/* Modifier: Large */

@mixin make-preview-large() {
  .preview__media {
    margin-bottom: 25px;
  }

  .preview__title {
    font-size: rem(24px);
    line-height: rem(28px);
  }

  .preview__title,
  .preview__desc {
    margin-bottom: 14px;
  }
}

@include media-breakpoint-down(sm) {
  .preview--mob-large {
    @include make-preview-large();

    margin-bottom: 45px;
  }
}

@include media-breakpoint-up(md) {
  .preview--desk-large {
    @include make-preview-large();
  }
}
