.skiplinks {
  background: color('blue');

  a {
    color: color('white');
  }
}

.skiplinks-placeholder {
  height: 0;
}
