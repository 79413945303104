@import '../../stylesheets/common';

.tcl-main-carousel__slider {
  .slick-dots {
    display: flex;
    justify-content: flex-start;
    position: relative;
    padding: 0 30px;
    bottom: 51px;
    margin: 0 -5px;

    li {
      margin: 0 5px;

      button {
        width: 51px;

        &::before {
          width: 41px;
          height: 7px;
          margin-left: -20.5px;
          border-radius: 0;
          background-color: #e8e8e8;
          opacity: 0.39;
          box-shadow: 0 0 15px 0 rgba(130, 130, 130, 0.5);
        }
      }

      &.slick-active {
        button {
          &::before {
            background-color: #fff;
            opacity: 1;
          }
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.tcl-main-carousel__slider__item {
  display: none;

  &:first-child {
    display: block;
  }
}

.tcl-main-carousel__slide {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 314px;
}

.tcl-main-carousel__anchor-overlay {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 0, 0, 0.2);
  border: none;
  opacity: 0;

  &,
  &:hover,
  &:focus {
    outline: none;
  }
}

.tcl-main-carousel__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tcl-main-carousel__content {
  position: relative;
  padding: 26px 0;
}

.tcl-main-carousel__occhiello {
  font-family: $font-family-serif;
  font-size: rem(13px);
  letter-spacing: rem(-0.28px);
  line-height: rem(20px);
  color: $dark;
}

.tcl-main-carousel__title {
  @include heading-h1();

  margin-bottom: 12px;
  color: $gray-800;
}

.tcl-main-carousel__abstract {
  font-size: rem(18px);
  letter-spacing: rem(-0.3px);
  line-height: rem(24px);
  margin-bottom: 15px;
  color: $dark;

  p {
    margin: 0;
  }
}

/* BREAKPOINT >= MD (TABLET and wider) */
@include media-breakpoint-up(sm) {
  .tcl-main-carousel__slider {
    .slick-dots {
      justify-content: flex-end;
      bottom: 60px;
      padding: 0 15px;
      margin-right: auto;
      margin-left: auto;

      li {
        &:last-child {
          margin-right: 0;
        }

        button {
          &::before {
            width: 51px;
            height: 9px;
            margin-left: -25.5px;
          }
        }
      }
    }
  }

  .tcl-main-carousel__slide {
    min-height: 530px;
  }

  .tcl-main-carousel__content {
    @include make-row();

    padding: 30px 0;
  }

  .tcl-main-carousel__occhiello,
  .tcl-main-carousel__title,
  .tcl-main-carousel__abstract,
  .tcl-main-carousel__foot {
    @include make-col-ready();
    @include make-col(5);
    // // hack to force each element on a new line
    flex: 0 0 51%;
    max-width: 51%;
    color: $white;
  }

  .tcl-main-carousel__title {
    margin-bottom: 18px;
  }

  .tcl-main-carousel .btn:not(:disabled):not(.disabled).active {
    box-shadow: none;
  }
}
