@import '../../stylesheets/common';

.tcl-sharer {
  margin-top: 15px;

  @include media-breakpoint-up(lg) {
    &.tcl-sharer--align-center {
      display: flex;
      justify-content: center;
    }
  }
}

.tcl-sharer__title {
  margin-right: 15px;
}

.tcl-sharer__column {
  display: flex;
}

.tcl-sharer__item {
  display: inline-block;
  padding: 0;
  margin: 0 3px 0 0;
  color: $black;
  background: none;
  border: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: $gray-800;
    text-decoration: none;
  }
}
