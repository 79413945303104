
// SVG Icons:
//
// Each svg file inside the /src/static/images/svg/ folder
// must have a corresponding class that specify it original dimensions.
// Moreover the /src/toolkit/docs/icons.html must contain one example for each icon.

.svg-icon--caret-left-fill {
  width: 16px;
  height: 16px;
}

.svg-icon--caret-right-fill {
  width: 16px;
  height: 16px;
}

.svg-icon--arrow-menu {
  width: 10px;
  height: 18px;
}

.svg-icon--arrow-link {
  width: 8px;
  height: 12px;
}

.svg-icon--burger {
  width: 26px;
  height: 18px;
}

.svg-icon--close {
  width: 24px;
  height: 24px;
}

.svg-icon--logo-cliclavoro-desktop {
  width: 189px;
  height: 55px;
}

.svg-icon--logo-cliclavoro-mobile {
  width: 182px;
  height: 36px;
}

.svg-icon--search-thin {
  width: 21px;
  height: 21px;
}

.svg-icon--search-thick {
  width: 23px;
  height: 24px;
}

/* Social (footer) */

.svg-icon--social-facebook {
  width: 38px;
  height: 38px;
}

.svg-icon--social-insta {
  width: 38px;
  height: 38px;
}

.svg-icon--social-linkedin {
  width: 38px;
  height: 38px;
}

.svg-icon--social-rss {
  width: 38px;
  height: 38px;
}

.svg-icon--social-twitter {
  width: 38px;
  height: 38px;
}

.svg-icon--social-youtube {
  width: 38px;
  height: 38px;
}

.svg-icon--filter {
  width: 26px;
  height: 22px;
}

/* Sharer */

.svg-icon--sharer-facebook {
  width: 45px;
  height: 31px;
}

.svg-icon--sharer-email {
  width: 45px;
  height: 31px;
}

.svg-icon--sharer-linkedin {
  width: 45px;
  height: 31px;
}

.svg-icon--sharer-twitter {
  width: 45px;
  height: 31px;
}
