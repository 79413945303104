@import '../../stylesheets/common';

.modal.modal-newsletter {
  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0 auto;
    width: 100vw;
    max-width: 700px;

    .modal-content {
      @include make-row();

      width: auto;
      box-shadow: 0 2px 19px 0 rgba(48, 48, 48, 0.5);
      position: relative;
      background: #fff;
      border-radius: 6px;
      padding: 48px 46px 54px;
      max-width: 100%;

      &,
      .close {
        color: #fff;
      }

      .modal-header,
      .modal-body {
        padding: 0 ($grid-gutter-width / 2);
      }

      .modal-header {
        position: absolute;
        margin: 0;
        top: -41px;
        right: 0;
        padding: 0;
      }

      .modal-body {
        padding: 0;

        h5 {
          color: #455b71;
          margin-bottom: 11px;
        }

        p {
          @include text-14();

          color: #455b71;
          margin-bottom: 40px;
        }

        .form-row:last-child .form-group {
          margin-bottom: 0;
        }

        .form-group {
          width: calc(50% - 11px);
          margin-bottom: 20px;

          &:first-child {
            margin-right: 22px;
          }

          input.form-control {
            font-family: $font-family-serif;
            font-size: rem(16px);
            line-height: rem(24px);
            letter-spacing: rem(-0.3px);
            padding: 13px 16px 11px;
            color: #455b71;
            border: 0;
            background: #f7f7f7;
            border-radius: 4px;
            -webkit-appearance: textfield;
            opacity: 1;
            height: 48px;

            &::placeholder {
              color: #455b71;
            }
          }
        }

        .btn-primary {
          margin: 27px 0 0;
          width: 100%;
          border-radius: 2px;
          height: 48px;
        }
      }
    }
  }
}

.modal-open--newsletter .modal-backdrop {
  background-color: #002862;

  &.show {
    opacity: 1;
  }
}

@include media-breakpoint-down(xs) {
  .modal.modal-newsletter .modal-dialog .modal-content {
    padding: 40px 30px 46px;
    margin: 0 32px;
  }

  .modal.modal-newsletter .modal-dialog .modal-content .modal-body .form-group {
    width: 100%;

    &:first-child {
      margin-right: 0;
    }
  }

  .modal.modal-newsletter .modal-dialog .modal-content .modal-body .btn-primary {
    margin-top: 20px;
  }
}
