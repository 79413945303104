.btn {
  padding: $btn-padding-y $btn-padding-x;
  margin: 5px 10px 5px 0;
  font-weight: 700;
  text-transform: uppercase;

  // Icon: arrow-link
  &[data-btn-icon="arrow-link"] {
    .svg-icon--arrow-link {
      margin-left: 4px;
      margin-top: -4px;
    }

    &[data-btn-icon-flip] .svg-icon--arrow-link {
      margin-left: 0;
      margin-right: 4px;
    }
  }

  // Flip the icon
  &[data-btn-icon-flip] {
    .svg-icon {
      transform: rotate(180deg);
    }
  }
}

.btn-primary {
  min-width: 150px;
  box-shadow: none;

  &:disabled,
  &.disabled {
    color: #fff;
    background-color: #eb7d01;
    border-color: #eb7d01;
  }
}

.btn-secondary {
  min-width: 150px;
  box-shadow: none;
}

.btn-white {
  $color: color('black');
  $background:color('white');
  @include button-variant($background, darken($background, 20%));

  color: $color;
  border-radius: 2px;
  box-shadow: none;
}

.btn-grey {
  $color: color('lead');
  $background: #ededed;
  @include button-variant($background, darken($background, 20%));

  color: $color;
  border-radius: 2px;
  font-size: rem(12px);
  box-shadow: none;
}

.btn-outline-secondary {
  color: color('blue');
  background: transparent;
  box-shadow: inset 0 0 0 2px color('blue');

  &:hover {
    color: color-hover(color('blue'));
    box-shadow: inset 0 0 0 2px color-hover(color('blue'));
  }

  &:disabled,
  &.disabled {
    color: color('blue');
    box-shadow: inset 0 0 0 2px color('blue');
  }
}

.btn-outline-form {
  color: color('lead');
  background: #fff;
  box-shadow: inset 0 0 0 1px #a3a3a2;
  font-weight: normal;
  font-size: rem(16px) !important;
  text-transform: none;

  &:hover {
    color: color-hover(color('lead'));
    background: #eee;
    box-shadow: inset 0 0 0 1px color-hover(#a3a3a2);
  }

  &:disabled,
  &.disabled {
    color: color('lead');
    box-shadow: inset 0 0 0 1px #a3a3a2;
  }
}

.btn-link {
  $color: color('lead');
  @include button-variant(transparent, $color);

  margin: 0;
  padding-left: 0;
  padding-right: 0;
  color: $color;
  font-size: rem(16px);
  line-height: rem(24px);
  font-weight: normal;
  text-transform: none;
  box-shadow: none !important;

  &:hover {
    color: $color;
  }

  &:disabled,
  &.disabled {
    color: $color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: color('lead');
    background-color: transparent;
    border-color: transparent;
  }
}

.btn-sm {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: rem(12px);
  line-height: rem(25px);

  &.btn {
    min-width: 100px;
  }
}

.btn-xs {
  min-width: 140px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: rem(10px);
}

/* Tag List */

.tag-list {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .btn {
    margin-top: 0;
    margin-bottom: 0;
    min-width: 0;
    white-space: nowrap;
  }
}
