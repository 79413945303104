.badge {
  margin: 0 5px 0 0;
  text-transform: uppercase;

  @each $color, $value in $theme-colors {
    &.badge-outline-#{$color} {
      padding: 2px 4px;
      border-radius: 3px;
      font-size: 10px;
      font-weight: 700;
    }
  }

  &.badge-md {
    padding: 2px 10px;
    border-width: 2px;
    border-radius: 4px;
    font-size: rem(10px);
    line-height: rem(22px);
    letter-spacing: rem(0.33px);
  }
}
