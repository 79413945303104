.hr {
  border: none;
  height: 1px;
  background: #f7f7f7;
}

.hr--thick {
  height: 28px;
}

.hr--dark {
  background-color: #979797;
}
