@import '../../stylesheets/common';

.tcl-sondaggi__title {
  @include heading-h5();

  display: block;
  margin-bottom: 22px;
}

.tcl-sondaggi__column {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}

.tcl-sondaggi__list-answer {
  padding-right: 20px;

  ul {
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 17px;

      .tcl-sondaggi__percentage {
        background-color: #eb7d01;
      }

      &:first-child {
        .tcl-sondaggi__percentage {
          background-color: #ebac01;
        }
      }

      &:last-child {
        .tcl-sondaggi__percentage {
          background-color: #009bcf;
        }
      }
    }
  }
}

.tcl-sondaggi__percentage {
  padding: 11px 10px;
  border-radius: 5px;
  margin-right: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  span {
    @include heading-h5();

    line-height: normal;
    text-align: center;
  }
}

.tcl-sondaggi__answer {
  @include heading-h6();

  font-weight: normal;
  color: #455b71;
}

/* BREAKPOINT < MD */
@include media-breakpoint-down(md) {
  .tcl-sondaggi__title {
    margin-bottom: 15px;
  }

  .tcl-sondaggi__column {
    margin-bottom: 40px;
  }
}
