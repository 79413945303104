
html,
body {
  font-size: $font-size-base;
  line-height: $line-height-base;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}

h1,
.h1 {
  @include heading-h1();
}

h2,
.h2 {
  @include heading-h2();
}

h3,
.h3 {
  @include heading-h3();
}

h4,
.h4 {
  @include heading-h4();
}

h5,
.h5 {
  @include heading-h5();
}

h6,
.h6 {
  @include heading-h6();
}

.link-14 {
  @include link-14();
}

.link-16 {
  @include link-16();
}

.link-18 {
  @include link-18();
}

.text-14 {
  @include text-14();
}

.text-15 {
  @include text-15();
}

.text-18 {
  @include text-18();
}

.text-tag-medium {
  @include text-tag-medium();
}
