@import '../../stylesheets/common';

/* Modifier: row separator */

.item-list--row-separator {
  .preview {
    padding-bottom: 22px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: solid 1px #979797;
    }

    &.preview--layout-search::after {
      left: 15px;
      right: 15px;
    }
  }

  @include media-breakpoint-up(md) {
    &.item-list--4col-desk {
      .preview {
        &:nth-child(4n)::after {
          right: 15px;
        }

        &:nth-child(4n+1)::after {
          left: 15px;
        }
      }
    }

    &.item-list--3col-desk {
      .preview {
        &:nth-child(3n)::after {
          right: 15px;
        }

        &:nth-child(3n+1)::after {
          left: 15px;
        }
      }
    }

    &.item-list--2col-desk {
      .preview {
        &:nth-child(2n)::after {
          right: 15px;
        }

        &:nth-child(2n+1)::after {
          left: 15px;
        }
      }
    }

    .preview.preview--layout-search::after {
      left: 15px;
      right: calc(16.66667% + 15px);
    }
  }

  @include media-breakpoint-up(xl) {
    .preview.preview--layout-search::after {
      left: calc(8.33333% + 15px);
    }

    .preview.preview--layout-search {
      &:last-child {
        &::after {
          border-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .item-list--2col-mob {
      .item-list__content {
        &:nth-child(2n)::after {
          right: 15px;
        }

        &:nth-child(2n+1)::after {
          left: 15px;
        }
      }
    }

    .item-list--1col-mob {
      .item-list__content {
        &:nth-child(1n)::after {
          right: 15px;
        }

        &:nth-child(1n+1)::after {
          left: 15px;
        }
      }
    }
  }
}

.item-list--1col-desk {
  .preview {
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
}

/* Modifier: 4/3/2 colonne su desktop */

.item-list--2col-desk,
.item-list--3col-desk {
  .preview {
    margin-bottom: 48px;
  }
}

@include media-breakpoint-up(md) {
  .item-list--4col-desk,
  .item-list--3col-desk,
  .item-list--2col-desk {
    .item-list__content {
      @include make-row();
    }

    .preview {
      @include make-col-ready();

      display: flex;
      flex-direction: column;
    }

    .preview__main {
      display: flex;
      flex-direction: column;
    }

    .preview__attachments,
    .preview__foot {
      margin-top: auto;
    }
  }

  .item-list--1col-desk .item-list__content {
    @include make-row();

    &.item-list__content--horizontal {
      display: flex;
      flex-direction: column;
    }
  }

  .item-list--4col-desk .preview {
    @include make-col(3);
  }

  .item-list--3col-desk .preview {
    @include make-col(4);
  }

  .item-list--2col-desk .preview {
    @include make-col(6);
  }
}

@include media-breakpoint-down(sm) {
  /* Modifier: 2 colonne su mobile */

  .item-list--2col-mob {
    .item-list__content {
      @include make-row();
    }

    .preview {
      @include make-col-ready();
      @include make-col(6);
    }

    .preview__title {
      font-size: rem(16px);
      line-height: rem(19px);
    }
  }

  /* Modifier: slider su mobile */

  .item-list--slider-mob {
    .item-list__content {
      margin-left: -32px;
      margin-right: -32px;
    }

    .slick-slide:first-child {
      margin-left: 32px;
    }

    .preview {
      width: calc(90vw - 64px) !important;
      max-width: 374px;
      margin-left: 32px;
    }

    .preview--layout-half {
      max-width: 275px;
    }
  }

  /* Modifier: Alternate background su mobile  */

  .item-list--alt-bg-mob {
    background: #f1f1f1;
    padding-top: 40px;
    padding-bottom: 10px;
  }
}

.container--splitted-layout {
  .item-list {
    .container {
      padding: 0;

      /* BREAKPOINT < MD */
      @include media-breakpoint-down(md) {
        padding: 0 15px;
      }

      /* BREAKPOINT < SM */
      @include media-breakpoint-down(sm) {
        .row {
          > div {
            padding: 0 17px;
          }
        }
      }

      /* BREAKPOINT < XS */
      @include media-breakpoint-down(xs) {
        padding: 0 32px;
      }
    }
  }
}
