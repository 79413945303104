@import '../../stylesheets/common';

.modal-search {
  .modal-dialog {
    @include make-container();
    @include make-container-cliclavoro-fix();
    @include make-container-max-widths();

    margin-top: 20px;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-top: 70px;
      margin-bottom: 70px;
    }

    .modal-content {
      @include make-row();

      width: auto;
      background: none;
      box-shadow: none;

      &,
      .close {
        color: #fff;
      }

      .modal-header,
      .modal-body {
        padding: 0 ($grid-gutter-width / 2);
      }

      .modal-header {
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 130px;
        }
      }
    }
  }
}

.modal-search__label {
  display: block;
  margin: 0 0 5px;
  font-family: $font-family-serif;
  font-size: rem(24px);
  line-height: rem(28px);
  letter-spacing: rem(-0.3px);
  font-weight: 300;
  color: #fff;
}

.modal-search__field {
  position: relative;
}

input[type='search'].modal-search__input {
  padding: 5px 0;
  height: 42px;
  font-family: $font-family-serif;
  font-size: rem(28px);
  line-height: rem(32px);
  letter-spacing: rem(-0.3px);
  font-weight: 700;
  color: #fff;
  border: 0;
  border-bottom: solid 2px rgba(255, 255, 255, 0.5);
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  @include media-breakpoint-up(lg) {
    padding: 5px 0;
    height: 90px;
    font-size: rem(70px);
    line-height: rem(80px);
    font-weight: 900;
  }
}

.modal-search__input:focus {
  outline: none;
}

.modal-search__button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background: none;
  margin: 0;
  padding: 5px 0;
  color: #fff;

  .svg-icon {
    width: 25px;
    height: 25px;
  }

  @include media-breakpoint-up(lg) {
    padding: 20px 0;

    .svg-icon {
      width: 48px;
      height: 48px;
    }
  }
}

.modal-open--search .modal-backdrop {
  background-color: #002862;

  &.show {
    opacity: 1;
  }
}
