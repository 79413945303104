@import '../../stylesheets/common';

.tcl-prev-next-nav {
  .pagination {
    width: 100%;

    .page-item {
      .page-link {
        background: unset;
        display: flex;
        flex-direction: column;
        height: auto;
        max-width: 225px;
        border: 0;
        padding-left: 0;
        padding-right: 0;
        box-shadow: unset;
        align-items: flex-end;
      }

      &.active {
        .page-link {
          align-items: flex-start;
        }
      }
    }
  }
}

.tcl-prev-next-nav__occhiello {
  @include text-14();

  font-family: $font-family-serif;
  display: block;
  color: $dark;
}

.tcl-prev-next-nav__title {
  @include heading-h5();

  display: block;
  color: $gray-800;
}

.container--splitted-layout {
  .tcl-prev-next-nav {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* BREAKPOINT < SM */
@include media-breakpoint-down(sm) {
  .container--splitted-layout {
    .tcl-prev-next-nav {
      .pagination {
        padding: 0 17px;
      }
    }
  }
}
